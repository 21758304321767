
@import 'src/styles/mixin';



.textContainer{
  padding: 50px 70px 150px;
  background-color: #FFFFFF;
  border: none;
  border-radius: 20px;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
}

.partRoot{
  margin-top: 80px;
}

.listContainer{

  display: flex;
  justify-content: center;
}
.list{

  text-align: left;

}

.linkAnticipating{
  cursor: pointer;
  color: #0080ff;
}
