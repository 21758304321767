.root{
  display: flex;
  justify-content: center;
  align-items: center;
}

.separator {
  height: 0;
  border: none;
  border-bottom: 1px solid #d4d4d4;
  border-radius: 10px;
  width: 30%;


}
