.logo{
  width: 40px;
  height: 40px;
}

.name{

}
.header{
  display: flex;

}

