@import 'src/styles/mixin';

.content{
  font-family: "Montserrat", Helvetica, Arial, serif;
  font-size: 20px;

  @include mobile{
    font-size: 17px;
  }
}

.textJustify{
  text-align: justify;
}

.textCenter{
  text-align: center;
}
