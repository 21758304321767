@import 'src/styles/mixin';

.h1{
  font-size: 40px;
  font-weight: 900;
  line-height: 1.4em;
  color: black;
  font-family: "Montserrat", Helvetica, Arial, serif;

  @include tablet{
    font-size: 30px;
  }

  @include mobile{
    font-size: 20px;
  }
}

.h2{
  font-size: 30px;
  @include tablet{
    font-size: 25px;
  }

  @include mobile{
    font-size: 18px;
  }
  color: black;
  font-weight: 800;
  font-family: "Montserrat", Helvetica, Arial, serif;
}

.h3{
  font-size: 20px;
  font-weight: 700;


  font-family: "Montserrat", Helvetica, Arial, serif;
}

.h1Container{
  margin-bottom: 35px;
  display: flex;
  justify-content: center;

}

.h2Container{
  display: flex;
  margin-bottom: 30px;
  margin-top: 50px;
}

.h3Container{
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 20px;
}
