@import 'src/styles/mixin';

.question{
  font-family: "Montserrat", Helvetica, Arial, serif;
  font-weight: bold;
  color: #424242;
  font-size: 24px;
  margin-bottom: 15px;
  text-align: center;


}

.root{
  border: 0.5px solid rgba(246, 151, 44, 0.44);
  box-shadow: 3px 3px 7px 0 rgba(246, 151, 44, 0.40);
  padding: 30px;
  border-radius: 20px;
  margin: 50px 120px;

  @include tablet{
    padding: 10px 20px;
    margin: 40px 20px;
  }

  @include mobile{
    padding: 10px 20px;
    margin: 40px 20px;
  }

}

