@import 'src/styles/mixin';

.root{
  padding: 150px 200px;
  @include mobile{
    padding:  12px 20px;
    padding-top: 120px;

  }

  background: rgb(225,245,254);
  background: linear-gradient(90deg, rgba(225,245,254,1) 0%, rgba(248,248,248,1) 40%);
}

.textContainer{
  padding: 50px 70px 20px;
  @include mobile{
    padding: 20px 20px;

  }
  background-color: #FFFFFF;
  border: none;
  border-radius: 20px;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
}

