@import '../../../../styles/config';

.pageacceuil-header-container{

  padding-top: 20px;
  font-family: 'Montserrat', Helvetica, Arial, serif;
  background-color: #FFFFFF;
  z-index: 999 !important;
  line-height: 1;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;

}



.pageacceuil-header-logo-container{
  display: flex;
  justify-content: flex-start;
  align-items: center;

}

.pageacceuil-header-logo{
  width: 55px;

  margin-right: 10px;

}

.pageacceuil-header-title{
  font-weight: bold;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;


}

@include media-breakpoint-down(xs){
  .pageacceuil-header-title-size{
    font-size: 1.3em;
  }

  .pageacceuil-header-logo-marginleft{
    margin-left: 0;
  }

  .pageacceuil-header-container-mobile{
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

}
@include media-breakpoint-up(sm){
  .pageacceuil-header-title-size{
    font-size: 1.5em;
  }

  .pageacceuil-header-logo-marginleft{
    margin-left: 25px;
  }
}



