.root{
  user-select: none;
  width: 100%;
  margin: 1.8rem 0 .8rem 1rem;

}

.title{

  color: #a6a4b0;

  font-family: Montserrat,Helvetica,Arial,serif;
  font-weight: 500;
  letter-spacing: 0.14px;
  line-height: 18.9px;
  font-size: 13px;
  text-transform: uppercase;
}