.root{

  padding: 10px 15px 10px 20px;
  border-radius: 4px;
  user-select: none;
  height: 42px;

  cursor: pointer;


}

.container{
  display: flex;
  transition: all 0.2s ease-out;
  &:hover {
    transform: translateX(8px);
  }
}
.selected {
  color: #fff;
  background: linear-gradient(118deg,#FCAE56,#FCAE56);
  box-shadow:   #f6972c 0 0 5px 0.5px;

}

.noSelected {
 color:  rgb(98, 95, 110);
  background: white;
}

.iconContainer{
  margin-right: 20px;
}

.title{
  letter-spacing: 0.14px;
  line-height: 22.33px;
  font-family: Montserrat, Helvetica, Arial, serif;
  font-size:15.4px;
  font-weight: 500;
}

