@import "config";
@import "bootstrap/scss/mixins/_breakpoints.scss";

@mixin mobile{
  @media (max-width: 766px) {
    @content;
  }
}

@mixin tablet{
  @media (min-width: 766px) and (max-width: 1200px) {
    @content;
  }
}



@mixin desktop{
  @media (min-width: 1200px) {
    @content;
  }
}


