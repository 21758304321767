@import '~bootstrap/scss/bootstrap-grid';

.root{

  padding-top: 30px;

  backdrop-filter: blur(6px);

  z-index: 1000;
  display: flex;
  justify-content: center;

  padding-left: 30px;
  padding-right: 30px;

  position: fixed;
  width: calc(100% - 260px);

  @include media-breakpoint-down(lg) {
    width: 100%;
  }
}

.iconMenuContainer{

  position: absolute;
  left: 0px;
  cursor: pointer;


  @include media-breakpoint-up(xs) {
    margin-left: 25px;

  }
  @include media-breakpoint-down(xs) {
    margin-left: 20px;

  }


}
.icon{
  color: #faa96c;
  width: 30px;
  height: 30px;
}

.appbar{
  filter: blur(0px);
  width: 100%;
  position: relative;
  height: 63px;
  background: white;
  border: none;
  border-radius: 20px;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 50px;
}

.nameContainer{
  display: flex;
  flex-direction: row;
  cursor: pointer;
  position: relative;


}
.name{
  font-family: Montserrat, Helvetica, Arial, serif;

  font-weight: bold;

}

.nameFreemium{
  color: #0080ff;
  text-decoration: underline;
}

.firstName{
  font-size: 20px;
}

.lastName{
  font-size: 13px;
}

.img{
  width: 45px;
  height: 45px;
  border-radius: 20px;
}

.iconContainer{
  padding-top: 4px;



  margin-left: 20px;
}

.icon{
  font-size: 38px;
}

.itemMenu{
  background-color: black;
}
