.circle{
  font-size: 11px;
  height: 11px;
  width: 11px;
}

.icon{
  width: 20px;
  height: 20px;
}


