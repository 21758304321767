.root{


  display: flex;
  flex-direction: column;
  gap: 2px;
  padding-left: 12px;
  padding-right: 12px;
  overflow-x: hidden;

}